import React, { useContext, useEffect } from "react"
import { ComponentIsActiveContext } from "../../../../context/Test/AppbonusNoStore/context"
import logEvent from "../../../../utils/logEvent"
import SEO from "../SEO/SEO"
import Header from "../Header/Header"
import Carousel from "../Carousel/Carousel"
import Footer from "../Footer/Footer"
import Tutorial from "../Tutorial/Tutorial"
import Loader from "../Loader/Loader"
import Tooltip from "../Tooltip/Tooltip"

const Layout = () => {
  const { componentIsActive, setComponentIsActive } = useContext(
    ComponentIsActiveContext
  )
  const mainTpl = (
    <>
      <Header />
      <Carousel />
      <Footer />
    </>
  )
  const tutorialTpl = <Tutorial />
  const loaderTpl = <Loader />
  const tooltipTpl = <Tooltip />

  useEffect(() => {
    logEvent("page view", { url: "/appbonus" })

    // Set main component with 1st load
    setComponentIsActive({
      ...componentIsActive,
      main: true,
    })
  }, [])

  return (
    <>
      <SEO/>
      {componentIsActive.main ? mainTpl : null}
      {componentIsActive.tutorial ? tutorialTpl : null}
      {componentIsActive.loader ? loaderTpl : null}
      {componentIsActive.tooltip ? tooltipTpl : null}
    </>
  )
}

export default Layout

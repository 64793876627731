import React from "react"
import { Helmet } from "react-helmet"
import useGTM from "../../../../hooks/useGTM"

const SEO = () => {
  const landingHeadConfig = {
    lang: "ru",
    gtm: "GTM-MJR3B6C",
    title: "Appbonus - мобильный заработок",
  }
  const helmetProdTpl = (
    <Helmet
      htmlAttributes={{
        lang: landingHeadConfig.lang,
      }}
      script={[
        {
          type: "text/javascript",
          innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${landingHeadConfig.gtm}');`,
        },
      ]}
      title={landingHeadConfig.title}
    />
  )
  const helmetDevTpl = (
    <Helmet
      htmlAttributes={{
        lang: landingHeadConfig.lang,
      }}
      title={landingHeadConfig.title}
    />
  )

  useGTM(landingHeadConfig.gtm)

  return process.env.NODE_ENV === "development" ? helmetDevTpl : helmetProdTpl
}

export default SEO

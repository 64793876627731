import React from "react"
import st from "./Footer.module.css"
import logo from "../../../../assets/images/Test/AppbonusNoStore/logo-appbonus--white.png"

const Footer = () => {
  return (
    <div className={st.footer}>
      <img className={st.footer__logo} src={logo} alt="Appbonus Logo White" />
      <p className={st.footer__copyright}>
        &copy;&nbsp;2020 All rights reserved
      </p>
    </div>
  )
}

export default Footer

import React, { useContext, useEffect, useState } from "react"
import { ComponentIsActiveContext } from "../../../../context/Test/AppbonusNoStore/context"
import platform from "platform"
import logEvent from "../../../../utils/logEvent"
import { useInterval } from "../../../../hooks/useInterval"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import st from "./Loader.module.css"

const Loader = () => {
  const { componentIsActive, setComponentIsActive } = useContext(
    ComponentIsActiveContext
  )
  const [progress, setProgress] = useState(0)
  const currentPlatform = platform.name
  const progressTick = 1000
  const progressPerTick = 4
  const loaderTime = 27000

  useEffect(() => {
    setTimeout(() => {
      logEvent(`tutorial ${currentPlatform} view`, {
        url: "/appbonus",
      })
      setComponentIsActive({
        ...componentIsActive,
        loader: false,
      })
    }, loaderTime)
  }, [])

  useInterval(() => {
    setProgress(progress + progressPerTick)
  }, progressTick)

  return (
    <div className={st.loader}>
      <div className={st.loader__content}>
        <p className={st.loader__text}>
          Приложение загружается.
          <br />
          Пожалуйста, подождите
        </p>
        <div className={st.loader__icon}>
          <CircularProgressbar
            value={progress}
            strokeWidth={50}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathTransitionDuration: 2,
              pathColor: `rgba(0,0,0,.8)`,
              trailColor: "transparent",
            })}
          />
        </div>
      </div>
    </div>
  )
}

export default Loader

import React from "react"
import Tutorial from "../../../Tutorial/Tutorial"
import step1 from "../../../../assets/images/Test/AppbonusNoStore/tutorial-not-safari__img-1@2x.png"
import step2 from "../../../../assets/images/Test/AppbonusNoStore/tutorial-not-safari__img-2@2x.png"
import step3 from "../../../../assets/images/Test/AppbonusNoStore/tutorial-not-safari__img-3@2x.png"
import step4 from "../../../../assets/images/Test/AppbonusNoStore/tutorial-not-safari__img-4@2x.png"

// Wrapper for existing component with static data
const TutorialNotSafari = () => {
  const data = [
    {
      Step_title: "Зайди в **“Настройки”** в раздел **“Основные”**",
      Step_image: {
        publicURL: step1,
      },
    },
    {
      Step_title:
        "Пролистай вниз и перейди в раздел **“Управление устройством”**",
      Step_image: {
        publicURL: step2,
      },
    },
    {
      Step_title: "Найди пункт **“65 GB, ООО”**",
      Step_image: {
        publicURL: step3,
      },
    },
    {
      Step_title: "Нажми **“Доверять”**",
      Step_image: {
        publicURL: step4,
      },
    },
  ]
  return <Tutorial tutorial={data} />
}

export default TutorialNotSafari

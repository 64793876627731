import React, { useContext } from "react"
import { ComponentIsActiveContext } from "../../../../context/Test/AppbonusNoStore/context"
import createAndClickLink from "../../../../utils/createLinkAndClick"
import logEvent from "../../../../utils/logEvent"
import st from "./ButtonDownload.module.css"

const ButtonDownload = ({ place }) => {
  const { componentIsActive, setComponentIsActive } = useContext(
    ComponentIsActiveContext
  )
  const appURL = "https://www.appbonus.ru/ios"
  const onClickHandler = () => {
    createAndClickLink(appURL)
    logEvent("click", {
      url: "/appbonus",
      button: `download-app ${place}`,
    })
    setComponentIsActive({
      ...componentIsActive,
      main: false,
      tutorial: true,
      loader: true,
    })
  }
  return (
    <button onClick={onClickHandler} className={st.button_download}>
      Установить
    </button>
  )
}

export default ButtonDownload

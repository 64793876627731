import React from "react"
import ButtonDownload from "../ButtonDownload/ButtonDownload"
import st from "./Header.module.css"
import logo from "../../../../assets/images/Test/AppbonusNoStore/logo-appbonus.png"

const Header = () => {
  return (
    <div className={st.header}>
      <img className={st.header__logo} src={logo} alt="Appbonus Logo" />
      <h1 className={st.header__title}>
        Выполняй простые задания и&nbsp;получай бонусы
      </h1>
      <ButtonDownload place="top" />
    </div>
  )
}

export default Header

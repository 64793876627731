import React, { useContext } from "react"
import { ComponentIsActiveContext } from "../../../../context/Test/AppbonusNoStore/context"
import logEvent from "../../../../utils/logEvent"
import { mobileProvisionUrl } from "../../../../utils/siteSettings"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import st from "./TutorialSafari.module.css"
import logo from "../../../../assets/images/Test/AppbonusNoStore/logo-appbonus--white.png"
import iconTooltip from "../../../../assets/images/Test/AppbonusNoStore/icon-tooltip.svg"
import slide1 from "../../../../assets/images/Test/AppbonusNoStore/tutorial-slide-1.png"
import slide2 from "../../../../assets/images/Test/AppbonusNoStore/tutorial-slide-2.png"
import arrowNext from "../../../../assets/images/Test/AppbonusNoStore/icon-arrow--white-right.svg"
import arrowPrev from "../../../../assets/images/Test/AppbonusNoStore/icon-arrow--white-left.svg"

const TutorialSafari = () => {
  const { componentIsActive, setComponentIsActive } = useContext(
    ComponentIsActiveContext
  )
  const onClickTooltip = () => {
    logEvent("click", { url: "/appbonus", button: `show hint` })
    setComponentIsActive({
      ...componentIsActive,
      tooltip: true,
    })
  }
  const onClickSettings = () => {
    logEvent("click", {
      url: "/appbonus",
      button: `open settings`,
    })
  }
  const carousel = [slide1, slide2]
  const ArrowNextTpl = props => {
    const { onClick } = props
    return (
      <button
        className={`${st.tutorial__carouselArrow} ${st.tutorial__carouselArrowNext}`}
        onClick={onClick}
      >
        <img src={arrowNext} alt="Next button arrow" />
      </button>
    )
  }
  const ArrowPrevTpl = props => {
    const { onClick } = props
    return (
      <button
        className={`${st.tutorial__carouselArrow} ${st.tutorial__carouselArrowPrev}`}
        onClick={onClick}
      >
        <img src={arrowPrev} alt="Previous button arrow" />
      </button>
    )
  }
  const carouselSettings = {
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    dots: false,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    nextArrow: <ArrowNextTpl />,
    prevArrow: <ArrowPrevTpl />,
  }

  return (
    <div className={st.tutorial}>
      <img className={st.tutorial__logo} src={logo} alt="Appbonus Logo White" />
      <h1 className={st.tutorial__title}>
        Установи
        <br />
        в&nbsp;два шага
      </h1>
      <p className={st.tutorial__caption}>
        Перейдите в&nbsp;<b>Настройки</b> <br />
        и&nbsp;нажмите <b>Доверять 65&nbsp;GB, OOO</b>{" "}
        <button onClick={onClickTooltip} className={st.tutorial__tooltip_btn}>
          <img src={iconTooltip} alt="Tooltip icon" />
        </button>
      </p>
      <a
        href={mobileProvisionUrl}
        onClick={onClickSettings}
        className={st.tutorial__btn}
      >
        В настройки
      </a>
      <div className={st.tutorial__carousel}>
        <Slider {...carouselSettings}>
          {carousel.map((item, index) => {
            return (
              <div className={st.tutorial__carousel_slide} key={index}>
                <img
                  className={st.tutorial__carousel_img}
                  src={item}
                  alt={`Tutorial slide #${index + 1}`}
                />
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

export default TutorialSafari

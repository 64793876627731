import React from "react"
import ButtonDownload from "../ButtonDownload/ButtonDownload"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import st from "./Carousel.module.css"
import screenshot1 from "../../../../assets/images/Test/AppbonusNoStore/screenshot-1.jpg"
import screenshot2 from "../../../../assets/images/Test/AppbonusNoStore/screenshot-2.jpg"
import screenshot3 from "../../../../assets/images/Test/AppbonusNoStore/screenshot-3.jpg"
import arrowNext from "../../../../assets/images/Test/AppbonusNoStore/icon-arrow--black-right.svg"
import arrowPrev from "../../../../assets/images/Test/AppbonusNoStore/icon-arrow--black-left.svg"

const Carousel = () => {
  const screenshots = [screenshot1, screenshot2, screenshot3]
  const ArrowNextTpl = props => {
    const { onClick } = props
    return (
      <button
        className={`${st.carousel__arrow} ${st.carousel__arrowNext}`}
        onClick={onClick}
      >
        <img src={arrowNext} alt="Next button arrow" />
      </button>
    )
  }
  const ArrowPrevTpl = props => {
    const { onClick } = props
    return (
      <button
        className={`${st.carousel__arrow} ${st.carousel__arrowPrev}`}
        onClick={onClick}
      >
        <img src={arrowPrev} alt="Previous button arrow" />
      </button>
    )
  }
  const carouselSettings = {
    dots: false,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    centerMode: true,
    centerPadding: "30px",
    nextArrow: <ArrowNextTpl />,
    prevArrow: <ArrowPrevTpl />,
  }

  return (
    <div className={st.carousel}>
      <Slider {...carouselSettings}>
        {screenshots.map((item, index) => {
          return (
            <div className={st.carousel__slide} key={index}>
              <div className={st.carousel__decoration_wrapper}>
                <img
                  className={st.carousel__img}
                  src={item}
                  alt={`Appbonus screenshot #${index + 1}`}
                />
              </div>
            </div>
          )
        })}
      </Slider>
      <ButtonDownload place="bottom" />
    </div>
  )
}

export default Carousel

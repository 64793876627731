import React from "react"
import { ComponentIsActiveContextProvider } from "../context/Test/AppbonusNoStore/context"
import Layout from "../components/Test/AppbonusNoStore/Layout/Layout"

const Appbonus = () => {
  return (
    <ComponentIsActiveContextProvider>
      <Layout />
    </ComponentIsActiveContextProvider>
  )
}

export default Appbonus

import React, { createContext, useState } from "react"

export const ComponentIsActiveContext = createContext()
export const ComponentIsActiveContextProvider = ({ children }) => {
  const [componentIsActive, setComponentIsActive] = useState({
    main: false,
    tutorial: false,
    loader: false,
    tooltip: false,
  })
  return (
    <ComponentIsActiveContext.Provider
      value={{ componentIsActive, setComponentIsActive }}
    >
      {children}
    </ComponentIsActiveContext.Provider>
  )
}

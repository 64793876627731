import React from "react"
import platform from "platform"
import TutorialNotSafari from "./TutorialNotSafari"
import TutorialSafari from "./TutorialSafari"

const Tutorial = () => {
  const currentPlatform = platform.name
  return currentPlatform === "Safari" ? (
    <TutorialSafari />
  ) : (
    <TutorialNotSafari />
  )
}

export default Tutorial

import React, { useContext } from "react"
import { ComponentIsActiveContext } from "../../../../context/Test/AppbonusNoStore/context"
import logEvent from "../../../../utils/logEvent"
import st from "./Tooltip.module.css"

const Tooltip = () => {
  const { componentIsActive, setComponentIsActive } = useContext(
    ComponentIsActiveContext
  )
  const onClickHandler = () => {
    logEvent("click", { url: "/appbonus", button: `close hint` })
    setComponentIsActive({
      ...componentIsActive,
      tooltip: false,
    })
  }
  return (
    <div className={st.tooltip}>
      <div className={st.tooltip__content}>
        <div className={st.tooltip__header}>
          <button onClick={onClickHandler} className={st.tooltip__close}>
            &times;
          </button>
        </div>
        <h2 className={st.tooltip__title}>Что такое 65 GB?</h2>
        <p className={st.tooltip__text}>
          Компания <b>65&nbsp;GB</b>&nbsp;&mdash; разработчик Appbonus, имеющий
          разрешение от&nbsp;Apple. В&nbsp;этом окне вы&nbsp;подтверждаете
          согласие установить Appbonus. Разработчик использует только
          те&nbsp;персональные данные, которые вы&nbsp;указываете
          самостоятельно.
        </p>
        <button onClick={onClickHandler} className={st.tooltip__btn}>
          Понятно
        </button>
      </div>
    </div>
  )
}

export default Tooltip
